<template>
  <lab-task
    ><lab-density-of-liquids-and-solids
      :density-of-unknown-liquid="liquidDensity"
      :density-of-metal="solidDensity"
    />
  </lab-task>
</template>

<script>
import LabDensityOfLiquidsAndSolids from '../simulations/LabDensityOfLiquidsAndSolids';
import LabTask from './labs/LabTask';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'DensityOfLiquidsAndSolidsSIM',
  components: {LabDensityOfLiquidsAndSolids, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    solidVersion() {
      return this.taskState.getValueBySymbol('solidVersion').content.value;
    },
    liquidVersion() {
      return this.taskState.getValueBySymbol('liquidVersion').content.value;
    },
    solidDensity() {
      return this.solidsMapping[this.solidVersion];
    },
    liquidDensity() {
      return this.liquidsMapping[this.liquidVersion];
    },
    solidsMapping() {
      return {
        1: 2.7, // aluminium
        2: 7.133, // zinc
        3: 8.746, // cobalt
        4: 12.4, // rhodium
        5: 19.6, // tungsten
        6: 7.31, // indium
        7: 22.65, // iridium
        8: 10.188, // molybdenum
        9: 8.908, // nickel
        10: 7.85, // steel
      };
    },
    liquidsMapping() {
      return {
        1: 0.786, // isopropanol
        2: 0.803, // n-propanol
        3: 0.867, // toluene
        4: 0.703, // octane
        5: 0.684, // hexane
        6: 0.92, // heating oil
        7: 0.625, // pentane
        8: 1.205, // propylene carbonate
        9: 0.979, // pyridine
        10: 0.7263, // decane
      };
    },
  },
});
</script>
