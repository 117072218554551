













import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export interface IDensityOfLiquidsAndSolidsInput {
  gradCylinderOneMassPerMl: number;
  gradCylinderTwoMassPerMl: number;
  metalGramsPerMl: number;
}

export default defineComponent({
  name: 'LabDensityOfLiquidsAndSolids',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    densityOfWater: {
      type: Number as PropType<number>,
      default: 0.998,
    },
    densityOfUnknownLiquid: {
      type: Number as PropType<number>,
      default: 0.998,
    },
    densityOfMetal: {
      type: Number as PropType<number>,
      default: 2.7,
    },
  },
  computed: {
    labData(): IDensityOfLiquidsAndSolidsInput {
      return {
        gradCylinderOneMassPerMl: this.densityOfWater,
        gradCylinderTwoMassPerMl: this.densityOfUnknownLiquid,
        metalGramsPerMl: this.densityOfMetal,
      };
    },
    stringifiedLabData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
